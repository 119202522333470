import React from "react"

import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import PrivacyPolicyNavigation
  from "../components/common/navigation/privacy-policy-navigation"
import TermsAndConditions from "../components/sections/terms-and-conditions"

const Privacy = () => (
  <Layout>
    <SEO title="Thanatech" description="Conditions générales d'utilisation" />
    <PrivacyPolicyNavigation />
    <TermsAndConditions />
  </Layout>
)

export default Privacy
